import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Axios from "axios";

function AddCarComponent() {
  const [inputFields, setInputFields] = useState({
    numarAuto: "",
    marca: "",
    model: "",
    culoare: "",
    serieSasiu: "",
    serieCarteIdentitate: "",
    capacitateCilindrica: "",
    mma: "",
    anulFabricatiei: "",
    tarif1: "",
    tarif2: "",
    tarif3: ""
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  function onCLickShowDiv() {
    var x = document.getElementById("myDIV");
    var button = document.getElementById("myButton");

    if (x.style.display === "none") {
      x.style.display = "flex";
      button.style.display = "none";
    } else {
      x.style.display = "none";
      button.style.display = "block";
    }
  }

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.numarAuto.length === 0) {
      errors.numarAuto = "Completeaza numarul auto";
    }
    if (inputValues.marca.length === 0) {
      errors.marca = "Completeaza marca";
    }
    if (inputValues.model.length === 0) {
      errors.model = "Completeaza modelul";
    }
    if (inputValues.culoare.length === 0) {
      errors.culoare = "Completeaza culoarea";
    }
    if (inputValues.serieSasiu.length === 0) {
      errors.serieSasiu = "Completeaza seria de sasiu";
    }
    if (inputValues.serieCarteIdentitate.length === 0) {
      errors.serieCarteIdentitate = "Completeaza seria cartii de identitate";
    }
    if (inputValues.capacitateCilindrica.length === 0) {
      errors.capacitateCilindrica = "Completeaza capacitatea cilindrica";
    }
    if (inputValues.mma.length === 0) {
      errors.mma = "Completeaza masa maxima autorizata";
    }
    if (inputValues.anulFabricatiei.length === 0) {
      errors.anulFabricatiei = "Completeaza anul fabricatiei";
    }
    if (inputValues.tarif1.length === 0) {
      errors.tarif1 = "Completeaza tariful 1";
    }
    if (inputValues.tarif2.length === 0) {
      errors.tarif2 = "Completeaza tariful 2";
    }
    if (inputValues.tarif3.length === 0) {
      errors.tarif3 = "Completeaza tariful 3";
    }
    // console.log({ inputValues: inputValues });
    return errors;
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (p) => {
    p.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const userData = {
      numarAuto: inputFields.numarAuto,
      marca: inputFields.marca,
      model: inputFields.model,
      culoare: inputFields.culoare,
      serieSasiu: inputFields.serieSasiu,
      serieCarteIdentitate: inputFields.serieCarteIdentitate,
      capacitateCilindrica: inputFields.capacitateCilindrica,
      mma: inputFields.mma,
      anulFabricatiei: inputFields.anulFabricatiei,
      tarif1: inputFields.tarif1,
      tarif2: inputFields.tarif2,
      tarif3: inputFields.tarif3
    };
    await Axios.post(`${BASE_URL}/addCar`, userData).then(() => {
      // console.log({ userData: userData });
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);

  return (
    <div>
      <Button
        variant="success"
        type="submit"
        onClick={onCLickShowDiv}
        style={{ marginTop: "20px" }}
        id="myButton"
      >
        Adauga masina
      </Button>
      <div
        className="add-car-form-container"
        style={{ display: "none" }}
        id="myDIV"
      >
        <Form onSubmit={handleSubmit} className="bd-example">
          <Form.Group className="mb-3" controlId="formBasicNrAuto">
            <Form.Label>Numar inmatriculare</Form.Label>
            <Form.Control
              type="text"
              name="numarAuto"
              value={inputFields.numarAuto}
              // placeholder="numarAuto"
              autoFocus
              onChange={handleChange}
              style={{ border: errors.numarAuto ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.numarAuto ? (
                <p className="error">{errors.numarAuto}</p>
              ) : (
                <p className="text-muted">
                  Adauga masina noua sub forma BC01MDL.
                </p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicMarca">
            <Form.Label>Marca</Form.Label>
            <Form.Control
              type="text"
              name="marca"
              value={inputFields.marca}
              // placeholder="Marca"
              autoFocus
              onChange={handleChange}
              style={{ border: errors.marca ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.marca ? (
                <p className="error">{errors.marca}</p>
              ) : (
                <p className="text-muted">Adauga marca masinii (ex. Dacia)</p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicModel">
            <Form.Label>Model</Form.Label>
            <Form.Control
              type="text"
              name="model"
              value={inputFields.model}
              // placeholder="Modelul"
              autoFocus
              onChange={handleChange}
              style={{ border: errors.model ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.model ? (
                <p className="error">{errors.model}</p>
              ) : (
                <p className="text-muted">Adauga modelul masinii (Ex. Logan)</p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicModel">
            <Form.Label>Culoare</Form.Label>
            <Form.Control
              type="text"
              name="culoare"
              value={inputFields.culoare}
              // placeholder="Modelul"
              autoFocus
              onChange={handleChange}
              style={{ border: errors.culoare ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.culoare ? (
                <p className="error">{errors.culoare}</p>
              ) : (
                <p className="text-muted">Adauga culoarea masinii</p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicModel">
            <Form.Label>Serie sasiu</Form.Label>
            <Form.Control
              type="text"
              name="serieSasiu"
              value={inputFields.serieSasiu}
              // placeholder="Modelul"
              autoFocus
              onChange={handleChange}
              style={{ border: errors.serieSasiu ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.serieSasiu ? (
                <p className="error">{errors.serieSasiu}</p>
              ) : (
                <p className="text-muted">Adauga seria de sasiu a masinii</p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicModel">
            <Form.Label>Serie cartii de identitate</Form.Label>
            <Form.Control
              type="text"
              name="serieCarteIdentitate"
              value={inputFields.serieCarteIdentitate}
              // placeholder="Modelul"
              autoFocus
              onChange={handleChange}
              style={{
                border: errors.serieCarteIdentitate ? "1px solid red" : null
              }}
            />
            <Form.Text className="text-muted">
              {errors.serieCarteIdentitate ? (
                <p className="error">{errors.serieCarteIdentitate}</p>
              ) : (
                <p className="text-muted">
                  Adauga seria cartii de identitate a masinii
                </p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicModel">
            <Form.Label>Capacitatea cilindrica</Form.Label>
            <Form.Control
              type="text"
              name="capacitateCilindrica"
              value={inputFields.capacitateCilindrica}
              // placeholder="Modelul"
              autoFocus
              onChange={handleChange}
              style={{
                border: errors.capacitateCilindrica ? "1px solid red" : null
              }}
            />
            <Form.Text className="text-muted">
              {errors.capacitateCilindrica ? (
                <p className="error">{errors.capacitateCilindrica}</p>
              ) : (
                <p className="text-muted">
                  Adauga capacitatea cilindrica a masinii
                </p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicModel">
            <Form.Label>Masa maxima autorizata</Form.Label>
            <Form.Control
              type="text"
              name="mma"
              value={inputFields.mma}
              // placeholder="Modelul"
              autoFocus
              onChange={handleChange}
              style={{ border: errors.mma ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.mma ? (
                <p className="error">{errors.mma}</p>
              ) : (
                <p className="text-muted">Adauga masa maxima a masinii</p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicModel">
            <Form.Label>Anul fabricatiei</Form.Label>
            <Form.Control
              type="text"
              name="anulFabricatiei"
              value={inputFields.anulFabricatiei}
              // placeholder="Modelul"
              autoFocus
              onChange={handleChange}
              style={{
                border: errors.anulFabricatiei ? "1px solid red" : null
              }}
            />
            <Form.Text className="text-muted">
              {errors.anulFabricatiei ? (
                <p className="error">{errors.anulFabricatiei}</p>
              ) : (
                <p className="text-muted">Adauga anul fabricatiei masinii</p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicModel">
            <Form.Label>Tarif 1</Form.Label>
            <Form.Control
              type="text"
              name="tarif1"
              value={inputFields.tarif1}
              // placeholder="tarif 1"
              autoFocus
              onChange={handleChange}
              style={{ border: errors.tarif1 ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.tarif1 ? (
                <p className="error">{errors.tarif1}</p>
              ) : (
                <p className="text-muted">
                  Adauga tariful recomandat in lei pentru 2-4 zile
                </p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicModel">
            <Form.Label>Tarif 2</Form.Label>
            <Form.Control
              type="text"
              name="tarif2"
              value={inputFields.tarif2}
              // placeholder="tarif 2"
              autoFocus
              onChange={handleChange}
              style={{ border: errors.tarif2 ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.tarif2 ? (
                <p className="error">{errors.tarif2}</p>
              ) : (
                <p className="text-muted">
                  Adauga tariful recomandat in lei pentru 5-14 zile
                </p>
              )}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicModel">
            <Form.Label>Tarif 3</Form.Label>
            <Form.Control
              type="text"
              name="tarif3"
              value={inputFields.tarif3}
              // placeholder="tarif 3"
              autoFocus
              onChange={handleChange}
              style={{ border: errors.tarif3 ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.tarif3 ? (
                <p className="error">{errors.tarif3}</p>
              ) : (
                <p className="text-muted">
                  Adauga tariful recomandat in lei pentru 15-30 zile
                </p>
              )}
            </Form.Text>
          </Form.Group>

          {Object.keys(errors).length === 0 && submitting ? (
            <span className="success">Adaugata cu succes! ✓</span>
          ) : (
            <Button variant="success" type="submit">
              Adauga masina
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
}

export default AddCarComponent;

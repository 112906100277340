import Login, { getStorageUser } from "./auth/login/Login";
// import { getStorageParams } from "./modal/blockCarModal";
import Dashboard from "./members/homepage/dashboard";
import AddCar from "./members/cars/addCar";
import AddUser from "./members/users/addUser";
import ChangePassword from "./auth/login/changePassword";
import Pdf from "./members/contracts/pdf";
import Contracte from "./members/contracts/contracte";
import RentedCars from "./members/rapoarte/rentedCars";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export const UserContext = React.createContext();

// require('dotenv').config()
// export const getStorageParams = () => {
//   const paramsStorage = sessionStorage.getItem("params");
//   if (paramsStorage) {
//       const params = JSON.parse(paramsStorage);
//       return params;
//   }
//   return null;
// };
function App() {
  // const BASE_URL = process.env.REACT_APP_BASE_URL;
  // console.log('BASE_URL', BASE_URL);

  const storageUser = getStorageUser();
  // const storageParams = getStorageParams();
  // console.log('read storageUser', storageUser);
  const [user, setUser] = useState(storageUser);
  // const [params, setParams] = useState(storageParams);
  // console.log('read storageParams', storageParams);

  // console.log('App');
  return (
    <div className="App">
      <BrowserRouter>
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <Routes>
            <Route path="/members/carReportPerMonth" element={<RentedCars />} />
            <Route exact index element={<Login />} />
            <Route path="/members/dashboard" element={<Dashboard />} />
            <Route path="/members/addCar" element={<AddCar />} />
            <Route path="/members/addUser" element={<AddUser />} />
            <Route path="/members/changePass" element={<ChangePassword />} />
            <Route path="/members/pdf" element={<Pdf />} />
            <Route path="/members/contracte" element={<Contracte />} />
          </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

const HoursModal = ({ contract, open, onClose, handleChangeDatas }) => {
  const [hours, setHours] = useState(contract?.nrOre || "");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (contract) {
      setHours(contract.nrOre || "");
    }
  }, [contract]);

  const handleSave = async () => {
    try {
      const response = await fetch(`${BASE_URL}/updateHours`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ContractId: contract.idContract, hours })
      });

      if (response.ok) {
        handleChangeDatas();
        onClose();
      } else {
        console.error("Eroare la salvarea statusului de reparație");
      }
    } catch (error) {
      console.error("Eroare de rețea:", error);
    }
  };

  const handleDeleteConfirm = () => {
    setConfirmDeleteOpen(true); // Deschide dialogul de confirmare
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${BASE_URL}/deleteHours`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ContractId: contract.idContract })
      });

      if (response.ok) {
        handleChangeDatas();
        onClose();
      } else {
        console.error("Eroare la ștergerea statusului de reparație");
      }
      setConfirmDeleteOpen(false); // Închide dialogul de confirmare
    } catch (error) {
      console.error("Eroare de rețea:", error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute", // Corecția poziționării
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Asigură centrarea pe ecran
            width: "400px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px"
          }}
        >
          {/* Butonul de închidere (X) */}
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "red"
            }}
          >
            <CloseIcon />
          </IconButton>

          <h4 style={{ paggingTop: "30px", paddingBottom: "20px" }}>
            Adaugă/Editează număr de ore
          </h4>
          <TextField
            label="Număr de Ore"
            type="number"
            fullWidth
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              onClick={handleSave}
              startIcon={<SaveIcon />}
            >
              Salvează
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDeleteConfirm}
              startIcon={<DeleteIcon />}
            >
              Șterge
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        sx={{ textAlign: "center", padding: "20px" }}
      >
        <DialogTitle>Confirmare ștergere</DialogTitle>
        <DialogContent>
          <p>Ești sigur că vrei să ștergi?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteOpen(false)}
            color="primary"
            variant="outlined"
            startIcon={<CancelIcon />}
          >
            Anulează
          </Button>
          <Button
            onClick={handleDelete} // Funcția care efectuează ștergerea
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Șterge
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HoursModal;

import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import formatDate from "../../utils/dateFormat";

const ContractDetails = ({ contracts, idContract }) => {
  const [formData, setFormData] = useState({
    dataConstatare: "",
    numarDosar: "",
    asigurator: "",
    inspector: "",
    pozeActe: {
      ci_vatamat: "",
      permis_vatamat: "",
      rca_vatamat: "",
      certificatAuto_vatamat: "",
      amiabila_vatamat: "",
      ci_vinovat: "",
      permis_vinovat: "",
      rca_vinovat: "",
      certificatAuto_vinovat: ""
    },
    pozeMasina: [
      { file: "", label: "Dreapta față" },
      { file: "", label: "Dreapta spate" },
      { file: "", label: "Stânga față" },
      { file: "", label: "Stânga spate" },
      { file: "", label: "Bord" },
      { file: "", label: "Serie sasiu" }
    ],
    pozeEveniment: Array(6).fill(null)
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleInputChange = (e, field) => {
    const { value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value
    }));
  };

  const handlePozeChange = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        pozeActe: { ...formData.pozeActe, [field]: file }
      });
    }
  };

  // const handleMasinaEvenimentChange = (e, field, index) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const updatedPoze = [...formData[field]];
  //     updatedPoze[index] = file;
  //     setFormData({ ...formData, [field]: updatedPoze });
  //   }
  // };

  const handleMasinaEvenimentChange = (e, type, index) => {
    // Dacă e null, înseamnă că vrei să ștergi poza
    if (!e && type && index !== undefined) {
      if (type === "pozeMasina") {
        setFormData((prevData) => {
          const updatedPozeMasina = [...prevData.pozeMasina];
          updatedPozeMasina[index] = null; // sau poți folosi splice pentru a elimina efectiv
          return { ...prevData, pozeMasina: updatedPozeMasina };
        });
      } else if (type === "pozeEveniment") {
        setFormData((prevData) => {
          const updatedPozeEveniment = [...prevData.pozeEveniment];
          updatedPozeEveniment[index] = null; // sau poți folosi splice pentru a elimina efectiv
          return { ...prevData, pozeEveniment: updatedPozeEveniment };
        });
      }
      return; // Oprirea execuției funcției
    }

    // Verifică dacă `e` este definit
    if (e && e.target) {
      const file = e.target.files[0];
      // Continuă logica de adăugare a fișierului
      if (type === "pozeMasina") {
        setFormData((prevData) => {
          const updatedPozeMasina = [...prevData.pozeMasina];
          updatedPozeMasina[index] = file;
          return { ...prevData, pozeMasina: updatedPozeMasina };
        });
      } else if (type === "pozeEveniment") {
        setFormData((prevData) => {
          const updatedPozeEveniment = [...prevData.pozeEveniment];
          updatedPozeEveniment[index] = file;
          return { ...prevData, pozeEveniment: updatedPozeEveniment };
        });
      }
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenDialog(true);
  };

  const handleImageDelete = (key) => {
    setFormData({
      ...formData,
      pozeActe: { ...formData.pozeActe, [key]: "" }
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
  };

  const handleSave = () => {
    console.log("Datele au fost salvate:", formData);
  };

  const handleAddPhoto = () => {
    setFormData((prevState) => ({
      ...prevState,
      pozeEveniment: [...prevState.pozeEveniment, null] // Adaugă un nou input pentru imagine
    }));
  };

  const handleReset = () => {
    setFormData({
      dataConstatare: "",
      numarDosar: "",
      asigurator: "",
      inspector: "",
      pozeActe: {
        ci_vatamat: "",
        permis_vatamat: "",
        rca_vatamat: "",
        certificatAuto_vatamat: "",
        amiabila_vatamat: "",
        ci_vinovat: "",
        permis_vinovat: "",
        rca_vinovat: "",
        certificatAuto_vinovat: ""
      },
      pozeMasina: [
        { file: "", label: "Dreapta față" },
        { file: "", label: "Dreapta spate" },
        { file: "", label: "Stânga față" },
        { file: "", label: "Stânga spate" },
        { file: "", label: "Bord" },
        { file: "", label: "Serie" }
      ],
      pozeEveniment: Array(6).fill(null)
    });
  };

  useEffect(() => {
    console.log("propsUseEffect", contracts);
    if (contracts && contracts.length > 0) {
      const selectedContract = contracts.find(
        (contract) => contract.idContract === idContract
      );
      console.log("Selected Contract:", selectedContract);
      setFormData((prevFormData) => ({
        ...prevFormData,
        dataConstatare:
          selectedContract.dataConstatare || prevFormData.dataConstatare,
        numarDosar: selectedContract.nrDosar || prevFormData.numarDosar,
        asigurator: selectedContract.asigurator || prevFormData.asigurator,
        inspector: selectedContract.inspector || prevFormData.inspector
      }));
    }
  }, [idContract]); // Aici punem contracts ca dependență

  console.log("props", contracts);
  console.log("formData", formData);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TextField
                label="Data Constatare"
                value={formatDate(formData.dataConstatare) || ""}
                onChange={(e) => handleInputChange(e, "dataConstatare")}
                fullWidth
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Numar Dosar"
                value={formData.numarDosar}
                onChange={(e) => handleInputChange(e, "numarDosar")}
                fullWidth
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Asigurator"
                value={formData.asigurator}
                onChange={(e) => handleInputChange(e, "asigurator")}
                fullWidth
              />
            </TableCell>
            <TableCell>
              <TextField
                label="Inspector"
                value={formData.inspector}
                onChange={(e) => handleInputChange(e, "inspector")}
                fullWidth
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              POZE ACTE VATAMAT
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              POZE ACTE VINOVAT
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              POZE MASINA
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              POZE EVENIMENT
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {/* POZE PERSOANA VATAMATA */}
            <TableCell style={{ verticalAlign: "top" }}>
              {Object.keys(formData.pozeActe)
                .slice(0, 5)
                .map((key) => (
                  <div key={key} style={{ marginBottom: "7px" }}>
                    <Card
                      sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        align="center"
                      >
                        {key.replace("_", " ").toUpperCase()}
                      </Typography>
                      {formData.pozeActe[key] && (
                        <CardMedia
                          component="img"
                          image={URL.createObjectURL(formData.pozeActe[key])}
                          alt={key}
                          sx={{
                            width: 100,
                            height: 100,
                            objectFit: "cover",
                            cursor: "pointer",
                            marginBottom: "10px"
                          }}
                          onClick={() =>
                            handleImageClick(formData.pozeActe[key])
                          }
                        />
                      )}
                      {!formData.pozeActe[key] && (
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<UploadFileIcon />}
                        >
                          Alege fișierul
                          <input
                            type="file"
                            hidden
                            onChange={(e) => handlePozeChange(e, key)}
                          />
                        </Button>
                      )}
                      {formData.pozeActe[key] && (
                        <Button
                          onClick={() => handleImageDelete(key)}
                          startIcon={<DeleteIcon />}
                          variant="contained"
                          color="error"
                          sx={{ marginTop: "10px" }}
                        >
                          Șterge
                        </Button>
                      )}
                    </Card>
                  </div>
                ))}
            </TableCell>

            {/* POZE PERSOANA VINOVATA */}
            <TableCell style={{ verticalAlign: "top" }}>
              {Object.keys(formData.pozeActe)
                .slice(5)
                .map((key) => (
                  <div key={key} style={{ marginBottom: "7px" }}>
                    <Card
                      sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        align="center"
                      >
                        {key.replace("_", " ").toUpperCase()}
                      </Typography>
                      {formData.pozeActe[key] && (
                        <CardMedia
                          component="img"
                          image={URL.createObjectURL(formData.pozeActe[key])}
                          alt={key}
                          sx={{
                            width: 100,
                            height: 100,
                            objectFit: "cover",
                            cursor: "pointer",
                            marginBottom: "10px"
                          }}
                          onClick={() =>
                            handleImageClick(formData.pozeActe[key])
                          }
                        />
                      )}
                      {!formData.pozeActe[key] && (
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={<UploadFileIcon />}
                        >
                          Alege fișierul
                          <input
                            type="file"
                            hidden
                            onChange={(e) => handlePozeChange(e, key)}
                          />
                        </Button>
                      )}
                      {formData.pozeActe[key] && (
                        <Button
                          onClick={() => handleImageDelete(key)}
                          startIcon={<DeleteIcon />}
                          variant="contained"
                          color="error"
                          sx={{ marginTop: "10px" }}
                        >
                          Șterge
                        </Button>
                      )}
                    </Card>
                  </div>
                ))}
            </TableCell>

            {/* POZE MASINA */}
            <TableCell style={{ verticalAlign: "top" }}>
              {formData.pozeMasina.map((poza, index) => (
                <div key={index} style={{ marginBottom: "7px" }}>
                  <Card
                    sx={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <Typography variant="subtitle2" gutterBottom align="center">
                      {poza.label.toUpperCase()}
                    </Typography>
                    {poza.file && (
                      <CardMedia
                        component="img"
                        image={URL.createObjectURL(poza)}
                        alt={poza.label}
                        sx={{
                          width: 100,
                          height: 100,
                          objectFit: "cover",
                          cursor: "pointer",
                          marginBottom: "10px"
                        }}
                        onClick={() => handleImageClick(poza.file)}
                      />
                    )}
                    {!poza.file && (
                      <Button
                        variant="outlined"
                        component="label"
                        startIcon={<UploadFileIcon />}
                      >
                        Alege fișierul
                        <input
                          type="file"
                          hidden
                          onChange={(e) =>
                            handleMasinaEvenimentChange(e, "pozeMasina", index)
                          }
                        />
                      </Button>
                    )}
                    {poza.file && (
                      <Button
                        onClick={() =>
                          handleMasinaEvenimentChange(null, "pozeMasina", index)
                        } // pentru poze masina
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        color="error"
                        sx={{ marginTop: "10px" }}
                      >
                        Șterge
                      </Button>
                    )}
                  </Card>
                </div>
              ))}
            </TableCell>

            {/* POZE EVENIMENT */}
            <TableCell style={{ verticalAlign: "top" }}>
              {formData.pozeEveniment.map((poza, index) => (
                <div key={index} style={{ marginBottom: "7px" }}>
                  <Card
                    sx={{
                      padding: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <Typography variant="subtitle2" gutterBottom align="center">
                      {`Poza eveniment ${index + 1}`}
                    </Typography>
                    {poza && (
                      <CardMedia
                        component="img"
                        image={URL.createObjectURL(poza)}
                        alt={`Eveniment ${index + 1}`}
                        sx={{
                          width: 100,
                          height: 100,
                          objectFit: "cover",
                          cursor: "pointer",
                          marginBottom: "10px"
                        }}
                        onClick={() => handleImageClick(poza)}
                      />
                    )}
                    {!poza && (
                      <Button
                        variant="outlined"
                        component="label"
                        startIcon={<UploadFileIcon />}
                      >
                        Alege fișierul
                        <input
                          type="file"
                          hidden
                          onChange={(e) =>
                            handleMasinaEvenimentChange(
                              e,
                              "pozeEveniment",
                              index
                            )
                          }
                        />
                      </Button>
                    )}
                    {poza && (
                      <Button
                        onClick={() =>
                          handleMasinaEvenimentChange(
                            null,
                            "pozeEveniment",
                            index
                          )
                        } // pentru poze masina
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        color="error"
                        sx={{ marginTop: "10px" }}
                      >
                        Șterge
                      </Button>
                    )}
                  </Card>
                </div>
              ))}
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={handleAddPhoto}
                  startIcon={<UploadFileIcon />}
                  color="success"
                >
                  Adaugă Foto
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ margin: "16px" }}
      >
        Salvează
      </Button>
      <Button variant="outlined" onClick={handleReset} color="secondary">
        Resetare
      </Button>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Imagine</DialogTitle>
        <DialogContent>
          {selectedImage && (
            <CardMedia
              component="img"
              image={URL.createObjectURL(selectedImage)}
              alt="Selected"
              sx={{ width: 400 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="error"
            startIcon={<CloseIcon />}
          >
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContractDetails;

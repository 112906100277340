import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as moment from "moment";
import Axios from "axios";

import Header from "../../navigation/header";
import Title from "../../navigation/title";
import { checkIsLoggedIn } from "../../auth/login/Login";
import ContractsList from "./contractsList";
import CalendarBar from "./calendarBar";

import "./index.css";

export const NewDateFormat = (yourDate) => {
  return moment(yourDate).format("YYYY-MM-DD");
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [contractsList, setContractsList] = useState([]);
  const [carsList, setCarsList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [managersList, setManagersList] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [counter, setCounter] = useState(0);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleChangeData = () => {
    setCounter((prev) => prev + 1);
  };

  const getLocationsList = async () => {
    const response = await Axios.get(`${BASE_URL}/getLocationsList`);
    setLocationsList(response.data);
  };

  const getManagersList = async () => {
    const response = await Axios.get(`${BASE_URL}/getManagersList`);
    setManagersList(response.data);
  };

  const getAgentsList = async () => {
    const response = await Axios.get(`${BASE_URL}/getAgentsList`);
    setAgentsList(response.data);
  };

  // useEffect(() => {
  //   console.log("contractsList", contractsList);

  // }, [counter]);

  useEffect(() => {
    getLocationsList();
    getManagersList();
    getAgentsList();

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0,
      23,
      59,
      59
    );

    const carData = {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      firstDayOfMonth: NewDateFormat(firstDay),
      lastDayOfMonth: NewDateFormat(lastDay)
    };

    Axios.post(`${BASE_URL}/getCarListForBar/`, carData)
      .then((res) => {
        setCarsList(res.data);
      })
      .catch((error) => {
        console.error("Error fetching contract list:", error);
      });

    const userData = {
      dataInceput: NewDateFormat(firstDay),
      dataSfarsit: NewDateFormat(lastDay)
    };
    console.log("userData", userData);
    Axios.post(`${BASE_URL}/contractsList/`, userData)
      .then((res) => {
        console.log("contractsList", res.data);
        setContractsList(res.data);
      })
      .catch((error) => {
        console.error("Error fetching contract list:", error);
      });
  }, [counter]);

  console.log("contractsListSTATE", contractsList);

  useEffect(() => {
    if (!checkIsLoggedIn()) {
      navigate("../../auth/login/Login");
    }
  }, [navigate]);

  return (
    <div className="dashboard">
      <Header className="header" />
      <Title title="Lista contracte" style={{ marginBottom: "20px" }} />
      <ContractsList
        className="contracts-list"
        contracts={contractsList}
        locationsList={locationsList}
        managersList={managersList}
        agentsList={agentsList}
        carsList={carsList}
        handleChangeData={handleChangeData}
      />
      <div className=" table-responsive">
        <div className="calendarBarFooter">
          {carsList.map((car) => (
            <CalendarBar key={car.carId} carId={car.carId} date={new Date()} />
          ))}
          <div style={{ height: "50px", background: "red" }}>Dummy Footer</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

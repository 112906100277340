import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import Axios from "axios";
import * as moment from "moment";

export const DeleteButton = (props) => {
  const NewDate = (yourDate) => {
    const newDate = moment(yourDate).format("YYYY-MM-DD");
    return newDate;
  };
  const [show, setShow] = useState(false);
  const dateStart = NewDate(props.dateStart);
  const dateEnd = NewDate(props.dateEnd);

  console.log({
    props: props,
    dateStart: dateStart,
    dateEnd: dateEnd
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (p) => {
    p.preventDefault();
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const userData = {
      carId: props.carId
    };

    await Axios.get(`${BASE_URL}/deleteCar/${props.carId}`, userData).then(
      () => {
        // console.log({ resStatus: res.status });
      }
    );
    let mess = new Date();
    props.onSubmitedDelete(mess);
  };

  useEffect(() => {
    finishSubmit();
  }, []);

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <i className="bi bi-trash3"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Stergi masina {props.nrAuto}?</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Nu
            </Button>
            <Button variant="success" onClick={handleClose} type="submit">
              Da
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../navigation/header";
import Title from "../../navigation/title";
import AddCarComponent from "./addCarComponent";
import AddCarList from "./addCarList";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { checkIsLoggedIn } from "../../auth/login/Login";

const AddCar = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkIsLoggedIn()) {
      navigate("../../auth/login/Login");
    }
  }, [navigate]);

  const userStorage = sessionStorage.getItem("user");

  if (userStorage) {
    var user = JSON.parse(userStorage);
    // console.log({user1: user.id})
  }

  return (
    <div>
      <Header />
      <Title title="Lista masini" />
      <div>
        <AddCarComponent userId={user.id} />
      </div>
      <div className="table-responsive">
        <AddCarList />
      </div>
    </div>
  );
};

export default AddCar;

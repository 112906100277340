import logo from "../assets/img/logo.jpg";
// import logo from "../../../assets/img/Rodof.png";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { UserContext } from "../App";
import { Link } from "react-router-dom";
// import { useCookies } from 'react-cookie';
import React, { useContext } from "react";

const Header = () => {
  const userStorage = sessionStorage.getItem("user");
  const { user, setUser } = useContext(UserContext);
  // const [cookies, removeCookie] = useCookies(["userCookie"]);

  const idRoleBool = user.idRole == 1 ? true : false;

  const logout = () => {
    setUser(null);
    // removeCookie('userCookie');
    sessionStorage.removeItem("user");
    window.location.href = "/";
    return false;
  };
  let currentButtonStatus = userStorage ? "Deconectare" : "Autentificare";

  return (
    <div>
      {/* <nav className="navbar navbar-expand-lg navbar-light bg-light"> */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid border-bottom border-2 pb-2">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt=""
              width="50"
              height="40"
              className="d-inline-block align-text-top pe-2"
            />
            MRC-masina de inlocuire app
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <Link to={"/members/dashboard"} className="nav-link">
                Prima pagina
              </Link>
              {idRoleBool && (
                <Link to={"/members/addCar"} className="nav-link">
                  Masini
                </Link>
              )}
              {idRoleBool && (
                <Link to={"/members/addUser"} className="nav-link">
                  Utilizatori
                </Link>
              )}
              {idRoleBool && (
                <Link to={"/members/contracte"} className="nav-link">
                  Dosare
                </Link>
              )}
              {idRoleBool && (
                <Link to={"/members/carReportPerMonth"} className="nav-link">
                  Rapoarte
                </Link>
              )}
              <Link to={"/members/changePass"} className="nav-link">
                Schimba parola
              </Link>
              <Link to={""} className="nav-link" onClick={logout}>
                {currentButtonStatus}
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;

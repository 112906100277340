import React from "react";

const Title = (props) => {
  return (
    <div>
      <div className="container-fluid mt-5">
        <span className="mb-0 mt-5 h3">{props.title}</span>
      </div>
    </div>
  );
};

export default Title;

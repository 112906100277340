import React, { useState, useEffect } from "react";
import ContractForm from "./addContractForm";
import ViewEditFileModal from "./modal/viewEditFileModal";
import RepairModal from "./modal/repairModal";
import HoursModal from "./modal/hoursModal";
import DaysModal from "./modal/daysModal";
import ContractDetails from "./contracDetails";
import CarSelectModal from "./modal/carSelectModal";
import DateTimeModal from "./modal/dateTimeModal";
import { Button, Modal, Box } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import TodayIcon from "@mui/icons-material/Today";
// import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import TimerIcon from "@mui/icons-material/Timer";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import formatDate from "../../utils/dateFormat";
import noImage from "../../assets/img/no-image.jpg";

const ContractsList = (props) => {
  // const [showForm, setShowForm] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null); // Stocăm indexul rândului extins
  const [showUploadModal, setShowUploadModal] = useState(false); // Starea pentru modalul de upload
  const [currentContractId, setCurrentContractId] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [contract, setContract] = useState(null);

  console.log("props", props);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const FILE_UPLOAD_PATH = process.env.REACT_APP_FILE_UPLOAD_PATH;

  // const handleOpen = () => setShowForm(true); // Deschide modalul
  // const handleClose = () => setShowForm(false); // Închide modalul
  const handleOpen = (modalType, contractId = null, file = null) => {
    // console.log("Opening modal:", modalType);
    setActiveModal(modalType);
    setCurrentContractId(contractId);
    setCurrentFile(file);
    setShowUploadModal(true);
  }; // Deschide modalul

  const handleClose = () => {
    // console.log("Closing modal");
    setActiveModal(null);
    setCurrentContractId(null);
    setCurrentFile(null);
    setShowUploadModal(false);
  }; // Închide modalul

  // const handleViewFile = (contractId, notaConstatare) => {
  //   setCurrentContractId(contractId);
  //   setCurrentFile(notaConstatare); // Setăm fișierul curent
  //   setShowUploadModal(true); // Deschide modalul
  // };

  // const handleOpenDaunaModal = (contractId) => {
  //   setCurrentContractId(contractId);
  //   setIsModalOpen(true);
  // };

  // const handleCloseDaunaModal = () => {
  //   setCurrentContractId(null);
  //   setIsModalOpen(false);
  // };

  // const handleUploadClose = (modalType) => setShowUploadModal(false); // Închide modalele

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index); // Schimbăm starea rândului
  };

  useEffect(() => {
    if (props.contracts && currentContractId) {
      const filteredItems = props.contracts.filter(
        (item) => item.idContract === currentContractId
      );
      if (filteredItems.length > 0) {
        setContract(filteredItems[0]);
      }
    }
  }, [currentContractId, props.contracts]);

  // console.log("activeModal", activeModal);
  return (
    <div>
      <Button
        open={showUploadModal}
        variant="contained"
        onClick={() => handleOpen("addContract", null, null)}
      >
        {showUploadModal ? "Anulează" : "Adaugă Contract"}
      </Button>

      {/* {console.log("showUploadModal", showUploadModal)}
      {console.log("activeModal2", activeModal)} */}

      {activeModal === "addContract" && (
        <Modal open={showUploadModal} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 1000,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px"
            }}
          >
            <ContractForm
              locationsList={props.locationsList}
              managersList={props.managersList}
              agentsList={props.agentsList}
              onClose={handleClose}
            />
          </Box>
        </Modal>
      )}

      <div className="table-responsive" style={{ marginTop: "20px" }}>
        <table className="table-container">
          <thead className="table-header">
            <tr>
              <th>Nr. Crt</th>
              <th>Locatie</th>
              <th>Manager</th>
              <th>Agent</th>
              <th>Nume Pagubit</th>
              <th>CNP</th>
              <th>Telefon</th>
              <th>Adresa</th>
              <th>Data eveniment</th>
              <th>Nota Constatare</th>
              <th>Tip Dauna</th>
              <th>Nr Ore</th>
              <th>Nr Zile</th>
              <th>Data Inceput</th>
              <th>Data Sfarsit</th>
              <th>Masina</th>
              <th>Mai multe...</th>
            </tr>
          </thead>
          <tbody>
            {props.contracts?.map((row, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{row.idContract}</td>
                  <td>{row.locatie}</td>
                  <td>{row.manager}</td>
                  <td>{row.agent}</td>
                  <td>{row.numePagubit}</td>
                  <td>{row.cnp}</td>
                  <td>{row.telefon}</td>
                  <td>{row.adresa}</td>
                  <td>
                    {row.dataEveniment && row.dataEveniment !== 0 ? (
                      formatDate(row.dataEveniment)
                    ) : (
                      <EventAvailableIcon fontSize="medium" />
                    )}
                  </td>
                  <td>
                    {/* {`${BASE_URL}${FILE_UPLOAD_PATH}/${row.notaConstatare}`} */}
                    {row.notaConstatare ? (
                      <img
                        src={`${BASE_URL}${FILE_UPLOAD_PATH}/${row.notaConstatare}`}
                        style={{ width: "50px", height: "50px" }}
                        alt="Nota de constatare"
                        onClick={() =>
                          handleOpen(
                            "notaConstatareUpload",
                            row.idContract,
                            row.notaConstatare
                          )
                        } // Deschidem modalul de vizualizare/editare
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noImage;
                        }}
                      />
                    ) : (
                      <EditNoteIcon
                        fontSize="medium"
                        onClick={() =>
                          handleOpen(
                            "notaConstatareUpload",
                            row.idContract,
                            null
                          )
                        } // Deschidem modalul pentru upload
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </td>
                  <td>
                    {row.tipDauna ? (
                      <span
                        onClick={() =>
                          handleOpen("tipDauna", row.idContract, row.tipDauna)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {row.tipDauna}
                      </span>
                    ) : (
                      <MinorCrashIcon
                        onClick={() => handleOpen("tipDauna", row.idContract)}
                      />
                    )}
                  </td>

                  <td>
                    {row.nrOre ? (
                      <span
                        onClick={() =>
                          handleOpen("nrOre", row.idContract, row.nrOre)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {row.nrOre}
                      </span>
                    ) : (
                      <QueryBuilderIcon
                        onClick={() => handleOpen("nrOre", row.idContract)}
                      />
                    )}
                  </td>
                  <td>
                    {row.nrZile ? (
                      <span
                      // onClick={() =>
                      //   handleOpen("nrZile", row.idContract, row.nrZile)
                      // }
                      // style={{ cursor: "pointer" }}
                      >
                        {row.nrZile}
                      </span>
                    ) : (
                      <TodayIcon
                      // onClick={() => handleOpen("nrZile", row.idContract)}
                      />
                    )}
                  </td>
                  <td>
                    {row.dataInceput && row.dataInceput !== 0 ? (
                      <span
                        onClick={() =>
                          handleOpen(
                            "dataInceput",
                            row.idContract,
                            row.dataInceput
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {formatDate(row.dataInceput)}
                      </span>
                    ) : (
                      <TimerIcon
                        fontSize="medium"
                        onClick={() =>
                          handleOpen("dataInceput", row.idContract)
                        }
                      />
                    )}
                  </td>
                  <td>
                    {row.dataSfarsit && row.dataSfarsit !== 0 ? (
                      <span>{formatDate(row.dataSfarsit)}</span>
                    ) : (
                      <TimerOffIcon fontSize="medium" />
                    )}
                  </td>
                  <td>
                    {row.idMasina && row.idMasina !== 0 ? (
                      <span
                        onClick={() =>
                          handleOpen("idMasina", row.idContract, row.idMasina)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {(() => {
                          const car = props.carsList.find(
                            (car) => car.carId === row.idMasina
                          );
                          return car ? car.numarAuto : "N/A";
                        })()}
                      </span>
                    ) : (
                      <DirectionsCarIcon
                        fontSize="medium"
                        onClick={() => handleOpen("idMasina", row.idContract)}
                      />
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => toggleRow(index)}
                      style={{ marginLeft: "10px", fontSize: "small" }}
                    >
                      {expandedRow === index ? "−" : "+"}
                    </button>
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr>
                    <td colSpan="15" style={{ backgroundColor: "#f9f9f9" }}>
                      <ContractDetails
                        contracts={props.contracts}
                        idContract={row.idContract}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {activeModal === "notaConstatareUpload" && (
        <ViewEditFileModal
          open={showUploadModal}
          onClose={handleClose}
          contractId={currentContractId}
          currentFile={currentFile}
          BASE_URL={BASE_URL}
          FILE_UPLOAD_PATH={FILE_UPLOAD_PATH}
          handleChangeDatas={props.handleChangeData}
        />
      )}
      {activeModal === "tipDauna" && (
        <RepairModal
          contract={contract}
          onClose={handleClose}
          open={showUploadModal}
          handleChangeDatas={props.handleChangeData}
        />
      )}
      {activeModal === "nrOre" && (
        <HoursModal
          contract={contract}
          open={showUploadModal}
          onClose={handleClose}
          handleChangeDatas={props.handleChangeData}
        />
      )}
      {activeModal === "nrZile" && (
        <DaysModal
          contract={contract}
          open={showUploadModal}
          onClose={handleClose}
          handleChangeDatas={props.handleChangeData}
        />
      )}
      {activeModal === "dataInceput" && (
        <DateTimeModal
          contract={contract}
          open={showUploadModal}
          onClose={handleClose}
          handleChangeDatas={props.handleChangeData}
        />
      )}
      {activeModal === "idMasina" && (
        <CarSelectModal
          contract={contract}
          contracts={props.contracts}
          contractId={currentContractId}
          carsList={props.carsList}
          open={showUploadModal}
          onClose={handleClose}
          handleChangeDatas={props.handleChangeData}
        />
      )}
    </div>
  );
};

export default ContractsList;

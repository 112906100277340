import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../navigation/header";
import Title from "../../navigation/title";
import ChangePassComponent from "./changePassComponent";
import "../../App.css";

import { checkIsLoggedIn } from "./Login";

const ChangePassword = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkIsLoggedIn()) {
      navigate("../../auth/login/Login");
    }
  }, [navigate]);

  // const userStorage = sessionStorage.getItem("user");
  // console.log('userStorage:', {userStorage})

  // if (userStorage) {
  //   var user = JSON.parse(userStorage);
  //   // console.log({user1: user})
  // }
  // console.log({user2: user.id})
  return (
    <div>
      <Header />
      <Title title="Schimba parola" />
      <ChangePassComponent />
    </div>
  );
};

export default ChangePassword;

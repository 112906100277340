import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Axios from "axios";
// import bcrypt from "bcryptjs";

function ChangePassComponent() {
  const [inputFields, setInputFields] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
    isOldPasswordWrong: ""
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  // let [reload, setReload] = useState(0);

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.oldPassword.length === 0) {
      errors.oldPassword = "Parola actuala este obligatorie!";
    }
    if (inputValues.newPassword1.length === 0) {
      errors.newPassword1 = "Parola noua este obligatorie!";
    }
    if (inputValues.newPassword2.length === 0) {
      errors.newPassword2 = "Confirmarea parolei este obligatorie!";
    }
    if (inputValues.newPassword1 !== inputValues.newPassword2) {
      errors.notEqual = "Parolele nu coincid!";
    }
    if (errors.isOldPasswordWrong === "notOk") {
      errors.isOldPasswordWrong = "Parola actuala nu este corecta!";
    }

    // console.log({ inputValues: inputValues });
    return errors;
  };

  const userStorage = sessionStorage.getItem("user");
  if (userStorage) {
    var user = JSON.parse(userStorage);
    // console.log({user1: user.id})
  }
  const verifyOldPass = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const userData = {
      oldPassword: inputFields.oldPassword,
      userId: user.id
    };

    // console.log({userData: userData, userStorage_id: user.id});

    await Axios.post(`${BASE_URL}/verifyOldPass`, userData).then((res) => {
      // console.log({ res: res });
      if (res.data.id > 0) {
        const message = "";
        setErrors({ isOldPasswordWrong: message });
        // inputFields.oldPassword
      } else {
        const message = "Parola actuala nu este corecta!";
        setErrors({ isOldPasswordWrong: message });
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "oldPassword") {
      verifyOldPass();
    }
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (p) => {
    p.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const userData = {
      newPassword: inputFields.newPassword1,
      userId: user.id
    };
    await Axios.post(`${BASE_URL}/changePass`, userData).then(() => {
      // console.log({ userData: userData });
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);
  console.log({ errors: errors });
  return (
    <div>
      <div className="change-password-form-container">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3 mt-5" controlId="formBasicOldPass">
            <Form.Label>Parola actuala</Form.Label>
            <Form.Control
              type="text"
              name="oldPassword"
              value={inputFields.oldPassword}
              autoFocus
              onChange={handleChange}
              onKeyUpCapture={verifyOldPass}
              style={{ border: errors.oldPassword ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.oldPassword && (
                <p className="error">{errors.oldPassword}</p>
              )}
              {errors.isOldPasswordWrong ? (
                <p className="error">{errors.isOldPasswordWrong}</p>
              ) : null}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicNewPassword1">
            <Form.Label>Parola noua</Form.Label>
            <Form.Control
              type="password"
              name="newPassword1"
              value={inputFields.newPassword1}
              autoFocus
              onChange={handleChange}
              style={{ border: errors.newPassword1 ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.newPassword1 ? (
                <p className="error">{errors.newPassword1}</p>
              ) : null}
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicNewPassword2">
            <Form.Label>Confirma parola noua</Form.Label>
            <Form.Control
              type="password"
              name="newPassword2"
              value={inputFields.newPassword2}
              autoFocus
              onChange={handleChange}
              style={{ border: errors.newPassword2 ? "1px solid red" : null }}
            />
            <Form.Text className="text-muted">
              {errors.newPassword2 ? (
                <p className="error">{errors.newPassword2}</p>
              ) : null}
              {errors.notEqual ? (
                <p className="error">{errors.notEqual}</p>
              ) : null}
            </Form.Text>
          </Form.Group>

          {Object.keys(errors).length === 0 && submitting ? (
            <span className="success">Schimbata cu succes! ✓</span>
          ) : (
            <Button variant="success" type="submit">
              Schimba parola
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
}

export default ChangePassComponent;

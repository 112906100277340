import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import Axios from "axios";

export const DeleteUserButton = (props) => {
  const [show, setShow] = useState(false);

  console.log({
    props: props
  });

  // const [inputFields, setInputFields] = useState({
  //     client: props.client,

  // });
  // const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const validateValues = (inputValues) => {
  //     let errors = {};
  //     return errors === false;
  // };

  // const handleChange = (e) => {
  //     setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  // };

  const handleSubmit = (p) => {
    p.preventDefault();
    // setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const userData = {
      userId: props.userId
    };
    // console.log({ userData: userData });
    await Axios.post(`${BASE_URL}/deleteUser/${props.userId}`, userData).then(
      (res) => {
        console.log({ resStatus: res.status });
      }
    );
    let mess = new Date();
    props.onSubmitedDelete(mess);
  };

  // console.log({ errors: errors });
  useEffect(() => {
    if (submitting) {
      finishSubmit();
      // console.log({ mes: "ok" });
    }
  }, [submitting]);

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <i className="bi bi-trash3"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Stergi utilizatorul {props.username}?</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Nu
            </Button>
            <Button variant="success" onClick={handleClose} type="submit">
              Da
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

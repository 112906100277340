import { format } from "date-fns";

const formatDate = (dateStr) => {
  if (!dateStr) {
    return "Data invalidă"; // sau returnează null sau un string gol
  }

  const date = new Date(dateStr);

  // Verifică dacă data este validă
  if (isNaN(date.getTime())) {
    return "Data invalidă"; // sau returnează un mesaj corespunzător
  }

  return format(date, "dd.MM.yyyy HH:mm");
};
export default formatDate;

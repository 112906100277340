import React, { useState } from "react";
import Header from "../../navigation/header";
import Title from "../../navigation/title";
import ContractList from "./contractList";
import DateFilter from "../homepage/dateFilter";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { checkIsLoggedIn } from "../../auth/login/Login";

const Contracte = () => {
  const [dataFromChildFilter, setLoadDataFilter] = useState({});
  const isLoggedIn = checkIsLoggedIn();

  console.log("Dashboard", { isLoggedIn });

  const getDataFromChildFilter = (dataFromChildFilter) => {
    setLoadDataFilter(dataFromChildFilter);
    console.log({ dataFromChildFilter: dataFromChildFilter });
  };
  // const userStorage = sessionStorage.getItem("user");

  // if (userStorage) {
  //   var user = JSON.parse(userStorage);
  //   console.loc(user);
  //   // if (!user) {
  //   //   // Redirect to login if no user
  //   //   window.location.href = "/login";
  //   //   return null;
  //   // }
  // }

  // console.log({dataFromChildFilter: dataFromChildFilter})
  return (
    <div>
      <div>
        <Header />
        <Title title="Lista contracte" />
        <DateFilter onSubmitedFilter={getDataFromChildFilter} />
      </div>
      <div className="table-responsive">
        <ContractList data={dataFromChildFilter} />
      </div>
    </div>
  );
};

export default Contracte;

import React, { useState } from "react";
import { Button } from "react-bootstrap";

const DateFilter = (props) => {
  const [inputFields, setInputFields] = useState({
    dataInceput: "",
    dataSfarsit: ""
  });
  const [errors, setErrors] = useState({});
  // const [submitting, setSubmitting] = useState(false);

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.dataInceput.length === 0) {
      errors.dataInceput = "Data inceput este obligatorie!";
    }
    if (inputValues.dataSfarsit.length === 0) {
      errors.dataSfarsit = "Data sfarsit este obligatorie!";
    }
    if (inputValues.dataInceput > inputValues.dataSfarsit) {
      errors.dataInceput = "Data inceput este mai mare decat data sfarsit!";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateValues(inputFields));
    // setSubmitting(true);
    props.onSubmitedFilter({
      dataInceput: inputFields.dataInceput,
      dataSfarsit: inputFields.dataSfarsit
    });
  };

  // setTimeout(() => {
  //     window.location.reload();
  //   }, 1000);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputFields({
      ...inputFields,
      [name]: value
    });
  };

  return (
    <>
      <div className="filter_contracts-list-form-container mb-4">
        <form onSubmit={handleSubmit} className="form-inline">
          {/* <div class="form-row"> */}
          <div className="form-group d-inline-flex align-items-center w-auto mt-1">
            <label className="mr-2 ml-2 ps-2" style={{ width: "150px" }}>
              Data start:
            </label>
            <input
              type="date"
              name="dataInceput"
              value={inputFields.dataInceput}
              autoFocus
              onChange={handleChange}
              className="form-control"
              style={{ border: errors.dataInceput ? "1px solid red" : null }}
            />

            {errors.dataInceput ? (
              <p className="error">{errors.dataInceput}</p>
            ) : null}
          </div>
          <div className="form-group d-inline-flex align-items-center w-auto mt-1">
            <label className="mr-2 ml-2 ps-2" style={{ width: "150px" }}>
              Data sfarsit:
            </label>
            <input
              type="date"
              name="dataSfarsit"
              value={inputFields.dataSfarsit}
              autoFocus
              onChange={handleChange}
              className="form-control"
              style={{ border: errors.dataSfarsit ? "1px solid red" : null }}
            />

            {errors.dataSfarsit ? (
              <p className="error">{errors.dataSfarsit}</p>
            ) : null}
          </div>
          <div className="form-group d-inline-flex align-items-center w-auto ps-4 mt-1 mb-2">
            <Button variant="success" type="submit">
              Cauta
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
export default DateFilter;

import React from "react";
import RentedCarsPerMonth from "./rentedCarsPerMonth";
import Header from "../../navigation/header";
import Title from "../../navigation/title";
import RentedCarsPerYear from "./rentedCarsPerYear";
import RentedCarsByUserPerYear from "./rentedCarsByUserPerYear";

const RentedCars = () => {
  // console.log({testRulareParinte: "testParinte"})

  return (
    <>
      <Header />
      <Title title="Rapoarte" />
      <RentedCarsPerMonth />
      <RentedCarsPerYear />
      <RentedCarsByUserPerYear />
    </>
  );
};
export default RentedCars;

import React from "react";
import { useState, useEffect } from "react";
import * as moment from "moment";
import Axios from "axios";

import "./index.css";
import "../../App.css";

export const NewDateFormat = (yourDate) => {
  const newDate = moment(yourDate).format("YYYY-MM-DD");
  return newDate;
};

const ContractList = (props) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [contractList, setContractList] = useState([]);
  // const [values, setValues] = useState(false);
  // const [dataFromChild, setDataFromChild] = useState(0);

  // const getDataFromChildEdit = (data) => {
  //     setDataFromChild(data);
  // }

  // const getDataFromChildDelete = (data) => {
  //     setDataFromChild(data);
  // }

  // const rowColored = (item) => {
  //   if (item % 2 === 0) {
  //     const bg = "border border-1 table-light";
  //     return bg;
  //   } else {
  //     const bg = "border border-1";
  //     return bg;
  //   }
  // };

  // console.log({propsData: props.data})
  useEffect(() => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // console.log({ firstDay: firstDay });
    // console.log({ lastDay: lastDay });
    const userData = {
      dataInceput: props.data.dataInceput
        ? props.data.dataInceput
        : NewDateFormat(firstDay),
      dataSfarsit: props.data.dataSfarsit
        ? props.data.dataSfarsit
        : NewDateFormat(lastDay)
    };
    // console.log({
    //   userData: userData,
    //   start: NewDateFormat(firstDay),
    //   end: NewDateFormat(lastDay)
    // });
    Axios.post(`${BASE_URL}/contractList/`, userData).then((res) => {
      setContractList(res.data);
      // setValues(true)
      // console.log({ cars: res.data })
    });
  }, [props.data]);

  const canGenerateContract = (row) => {
    return Object.values(row).every((value) => value);
  };
  // console.log({ listCars: listCars })
  return (
    <div>
      <div className="contracts-list-form-container">
        <table className="table-container">
          <thead className="table-header">
            <tr>
              <th>Nr. Crt</th>
              <th>Locatie</th>
              <th>Manager</th>
              <th>Agent</th>
              <th>Nume Pagubit</th>
              <th>CNP</th>
              <th>Telefon</th>
              <th>Adresa</th>
              <th>Nota de Constatare</th>
              <th>Tip Dauna</th>
              <th>Nr Ore</th>
              <th>Nr Zile</th>
              <th>Data Inceput</th>
              <th>Masina</th>
              <th>Genereaza Contract</th>
            </tr>
          </thead>
          <tbody>
            {contractList.map((row, index) => (
              <tr key={index}>
                <td>{row.nrCrt}</td>
                <td>{row.locatie}</td>
                <td>{row.manager}</td>
                <td>{row.agent}</td>
                <td>{row.numePagubit}</td>
                <td>{row.cnp}</td>
                <td>{row.telefon}</td>
                <td>{row.adresa}</td>
                <td>{row.notaDeConstatare}</td>
                <td>{row.tipDauna}</td>
                <td>{row.nrOre}</td>
                <td>{row.nrZile}</td>
                <td>{row.dataInceput}</td>
                <td>{row.masina}</td>
                <td>
                  <button
                    onClick={() =>
                      alert(`Generează contract pentru ${row.numePagubit}`)
                    }
                    disabled={!canGenerateContract(row)}
                  >
                    Genereaza Contract
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="legenda">
                <Container fluid>
                <Row md={5} style = {{ justifyContent: 'center', paddingBottom:5 }}>LEGENDA</Row>
                    <hr />
                    <Row md={5}  style = {{ justifyContent: 'center' }}>
                        <Col>
                            <Button
                                variant="primary"
                            >
                                <i class="bi bi-pencil-square"></i>
                            </Button>
                            <br />
                            Editeaza rezervarea
                        </Col>
                        <Col>
                            <Button
                                variant="danger"
                            >
                                <i class="bi bi-trash3"></i>
                            </Button><br />
                            Sterge masina
                        </Col>
                    </Row>
                </Container>
            </div> */}
    </div>
  );
};

export default ContractList;

import React from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  IconButton,
  MenuItem,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Axios from "axios";

const CarSelectModal = ({
  open,
  onClose,
  handleChangeDatas,
  contractId,
  contract,
  contracts,
  carsList
}) => {
  const [car, setCar] = React.useState("");
  const [carsListForModal, setCarsListForModal] = React.useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    if (contract) {
      const startDate = contract.dataInceput;
      const endDate = contract.dataSfarsit;

      // Resetăm mesajul de eroare
      setErrorMessage("");

      if (!startDate) {
        setErrorMessage("Te rugăm să alegi mai întâi data de început.");
        setCarsListForModal([]); // Golim lista de mașini
        return;
      }

      const availableCars = getAvailableCars(
        startDate,
        endDate,
        carsList,
        contracts
      );
      setCarsListForModal(availableCars);
    }
  }, [contractId, contract, carsList, contracts]);

  const periodsOverlap = (start1, end1, start2, end2) => {
    return (
      new Date(start1) <= new Date(end2) && new Date(end1) >= new Date(start2)
    );
  };

  const isCarAvailable = (carId, startDate, endDate, contracts) => {
    for (const contractFor of contracts) {
      if (contractFor.idMasina === carId) {
        const contractStartDate = contractFor.dataInceput;
        const contractEndDate = contractFor.dataSfarsit;

        // Verificăm dacă dataSfarsit este null
        if (!contractEndDate) {
          return false; // Mașina este ocupată pe toată perioada
        }

        if (
          contractStartDate &&
          periodsOverlap(startDate, endDate, contractStartDate, contractEndDate)
        ) {
          return false; // Mașina este ocupată
        }
      }
    }
    return true; // Mașina este disponibilă
  };

  const getAvailableCars = (startDate, endDate, cars, contracts) => {
    return cars.filter((car) =>
      isCarAvailable(car.carId, startDate, endDate, contracts)
    );
  };

  const handleSave = async () => {
    if (!car) {
      console.error("Nu a fost selectată nicio mașină.");
      return; // Oprește execuția dacă nu este selectată nicio mașină
    }

    try {
      const response = await Axios.post(`${BASE_URL}/setCarForReplacement`, {
        carId: car,
        contractId: contractId
      });

      if (response.status === 200) {
        handleChangeDatas();
        onClose();
      } else {
        console.error("Eroare la selectarea mașinii");
      }
    } catch (error) {
      console.error("Eroare de rețea:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        {/* Afișăm mesajul de eroare dacă este cazul */}
        {errorMessage && (
          <Typography color="error" sx={{ mb: 2 }}>
            {errorMessage}
          </Typography>
        )}

        <TextField
          select
          label="Mașină"
          value={car}
          onChange={(e) => setCar(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          disabled={!!errorMessage} // Dezactivăm selectul dacă există un mesaj de eroare
        >
          {carsListForModal.length > 0 ? (
            carsListForModal.map((car) => (
              <MenuItem key={car.carId} value={car.carId}>
                {car.numarAuto} - {car.marca} {car.model}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>Nicio mașină disponibilă</MenuItem>
          )}
        </TextField>
        <Button variant="contained" onClick={handleSave} endIcon={<SaveIcon />}>
          Salvează
        </Button>
      </Box>
    </Modal>
  );
};

export default CarSelectModal;

import React from "react";
import Header from "../../navigation/header";
import Title from "../../navigation/title";
import AddUserComponent from "./addUserComponent";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import UsersList from "./addUsersListComp";

import { checkIsLoggedIn } from "../../auth/login/Login";

const AddCar = () => {
  const isLoggedIn = checkIsLoggedIn();

  console.log("Dashboard", { isLoggedIn });

  const userStorage = sessionStorage.getItem("user");

  if (userStorage) {
    var user = JSON.parse(userStorage);
    // console.log({user1: user.id})
  }

  return (
    <div>
      <Header />
      <Title title="Lista utilizatori" />
      <div>
        <AddUserComponent userId={user.id} />
      </div>
      <div className="table-responsive">
        <UsersList userId={user.id} />
      </div>
    </div>
  );
};

export default AddCar;
